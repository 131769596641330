// NOMENSA button.scss
.btn,
input.btn {
  border-radius: 0.25rem;
  font-family: $secondary-font-bold;
  font-size: 1.125rem;
  letter-spacing: 0.5px;
  min-width: 136px;
  padding: 0.5rem 2.1rem;
  white-space: normal;

  &.btn-negative,
  &.btn-neutral,
  &.btn-positive,
  &.btn-negative-answer,
  &.btn-neutral-answer,
  &.btn-positive-answer {
    border: 1px solid transparent;
    color: $black;
    min-width: 100px !important;
    padding: 0 !important;
    border-radius: 5px;

    &.focus,
    &:focus,
    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: 0 0 0 0.2rem $shadow-black !important;
    }
  }

  &.btn-positive:not(:disabled):not(.disabled):active,
  &.btn-neutral:not(:disabled):not(.disabled):active,
  &.btn-negative:not(:disabled):not(.disabled):active,
  &.btn-negative-answer:not(:disabled):not(.disabled):active,
  &.btn-neutral-answer:not(:disabled):not(.disabled):active,
  &.btn-positive-answer:not(:disabled):not(.disabled):active {
    border: 1px solid transparent;
    color: $black;
    text-decoration: underline;
    border-radius: 5px;
  }

  &.question-actions-link {
    font-family: $secondary-font !important;
  }

  @include respond-above(sm) {
    min-width: 180px;
  }

  &:hover {
    text-decoration: underline;
  }

  &.disabled,
  &:disabled {
    text-decoration: none;
  }

  &.btn-link {
    color: $black;
    min-width: initial;
    padding: initial;

    &:hover {
      background-color: transparent;
    }

    &:not(:disabled):not(.disabled):active {
      background-color: $gray-118;
      border-color: $gray-118;
    }

    &.focus,
    &:focus,
    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: 0 0 0 0.2rem $shadow-3;
    }

    &.disabled,
    &:disabled {
      color: $gray-136;
    }

    &.btn-sm {
      font-size: 1rem;
      line-height: 1.5;
    }

    &.primary-font-link {
      font-family: $primary-font;
      letter-spacing: inherit;
      font-size: inherit;
      vertical-align: inherit;
      text-decoration: underline;
      &:focus {
        outline: 0.2rem solid #000 !important;
        border-radius: inherit;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }

  //Button - Primary Outline
  &.btn-outline-primary {
    border-color: $black;
    border-width: 2px;
    color: $black;

    &:hover {
      background-color: transparent;
    }

    &:not(:disabled):not(.disabled):active {
      background-color: $gray-118;
      border-color: $gray-118;
    }

    &.focus,
    &:focus,
    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: 0 0 0 0.2rem $shadow-3;
    }

    &.disabled,
    &:disabled {
      border-color: $gray-136;
      color: $gray-136;
    }
  }

  //Button - Secondary Outline
  &.btn-outline-secondary {
    background-color: $black;
    border-color: $white;
    border-width: 2px;
    color: $white;

    &:not(:disabled):not(.disabled):active {
      background-color: $gray-118;
      border-color: $gray-118;
    }

    &.focus,
    &:focus,
    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: 0 0 0 0.2rem $shadow-3;
    }

    &.disabled,
    &:disabled {
      border-color: $gray-160;
      color: $gray-160;
    }
  }
  &.btn-xl {
    width: 100%;
    white-space: nowrap;

    @include respond-above(md) {
      padding-right: 5rem;
      padding-left: 5rem;
      width: auto;
    }
  }
}

// ISAAC
.btn-underline {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

a.btn {
  text-decoration: none;
}
.btn {
  &.btn-link {
    font-size: initial;
    font-weight: initial;
  }

  &.btn-sm {
    padding: 0.25rem 0.75rem;
    font-size: 1rem;
    min-width: 80px;
  }

  &.btn-tertiary {
    background: $gray-107;
    color: $black;
  }

  &.btn-outline-tertiary {
    border-color: $gray-118;
    border-width: 2px;
    color: $black;

    &:hover {
      background-color: transparent;
    }

    &:not(:disabled):not(.disabled):active {
      background-color: $gray-136;
      border-color: $gray-136;
    }

    &.focus,
    &:focus,
    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: 0 0 0 0.2rem $shadow-08;
    }

    &.disabled,
    &:disabled {
      border-color: $gray-107;
      color: $gray-107;
    }
  }
}

.btn.plus-button {
  position: relative;
  padding-right: 52px;
  &::after {
    @include pseudo-element();
    background-image: url("/assets/add.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: center;
    height: 100%;
    right: 26px;
    top: 0;
    bottom: 0;
    width: 20px;
  }
}

.quick-question-options {
  border-radius: 5px;
}

.border-muted {
  border-color: grey !important;
}
