// ISAAC

.input-group.is-invalid {
  &:after {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: solid 1px theme-color("danger");
    pointer-events: none;
    @include border-radius($input-border-radius, 0);
  }

  .close {
    color: theme-color("danger");
  }
}

.input-group.is-valid {
  &:after {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: solid 1px theme-color("success");
    pointer-events: none;
    @include border-radius($input-border-radius, 0);
  }

  .close {
    color: theme-color("success");
  }
}

.date-input .is-invalid,
.date-input .is-valid {
  background: unset;
}

.date-input {
  select.form-control {
    padding: 0.5rem;
    .board-card & {
      padding: 0.15rem;
    }
  }
}

.date-input-day {
  flex-grow: 2 !important;
}

.date-input-month {
  flex-grow: 3 !important;
}

.date-input-year {
  flex-grow: 3 !important;
}

// NOMENSA forms.scss

input {
  &.form-control {
    @include placeholder($gray-500);
    border: solid 1px $black;
    padding: 0 1.2em;
  }
}

select {
  &.form-control {
    border: solid 1px $black;
    height: 48px;
    padding: 0 1.2em;

    &[name="selectMulti"] {
      padding: 1rem 1.2rem 1.5rem;
    }
  }
}

textarea {
  &.form-control {
    border: solid 1px $black;
    padding: 0.7rem 1.2rem; // 0;  // TextAreas look wrong with this 0 for bottom padding. Remove it.

    &.answer {
      height: 48px;
    }
  }
}

[type="search"] {
  @include placeholder($black);
  border: 0;
  border-radius: ($border-radius * 10);
  height: 2.6rem;
  padding: initial 40px initial 1rem;

  @include respond-above(sm) {
    max-width: 220px;
  }

  &.form-control {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.search--filter-input {
    border-width: 0;
    box-shadow: 0 2px 30px 0 $shadow-08;
    max-width: 300px;
    width: 100%;
  }
}

button {
  &.search-main {
    height: 36px;
    min-width: auto;
    right: 4px;
    top: 2px;
    width: auto;
    display: flex;
    align-items: center;

    .search-wrapper {
      display: flex;
      align-items: center;
      background-color: #d60265;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &:hover {
      background-color: transparent;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem $shadow-25;
    }

    svg {
      display: flex;
    }
  }
}

.search--main-group,
.search--filter-group {
  &.form-group {
    display: flex;
    margin: 0;
    position: relative;
    flex-wrap: nowrap;
    align-items: center;

    input {
      &.form-control {
        border-width: 0;
        padding: 0 1rem;
      }
    }
  }
}

// ISAAC

label.label-radio > div {
  display: inline-block;
  padding-left: 1em;
}

.form-required {
  &:after {
    content: "*";
    padding-left: 6px;
    font-weight: 600;
    color: $secondary;
  }
}

.required-before {
  &:before {
    content: "* ";
    padding-left: 6px;
    font-weight: 600;
    color: $secondary;
  }
}

// Position correctly at all sizes
.form-inline .form-check-input {
  position: relative;
  flex-shrink: 0;
  margin-top: 0;
  margin-right: 0.25rem;
  margin-left: 0;
}

// Remove spinner from date picker
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select.form-control {
  height: calc(1.5em + 0.75rem + 2px);
}

.basic-multi-select {
  .select__control.select__control--is-focused {
    box-shadow: 0 0 0 0.2rem $focus-blue;
  }
  .select__control,
  .select__control:hover {
    border-color: $black;
  }

  .select__menu {
    z-index: 2;
    .select__option.select__option--is-focused {
      background-color: $primary;
    }
  }
}

.was-validated select.form-control:invalid,
select.form-control.is-invalid {
  padding-right: 0 !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
  border-width: 2px;
}

.password-group {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 1000;
  cursor: pointer;
}
