.carousel {
  .carousel-item {
    > div {
      padding: 6px;
      display: flex;
      justify-content: center;
    }
  }
  .carousel-indicators {
    margin-top: 3rem;
    li {
      background-color: transparent;
      border: 2px solid $black;
      border-radius: 50%;
      height: 12px;
      opacity: 1;
      width: 12px;

      &.active {
        background-color: $secondary;
        border-color: $secondary;
      }
    }
  }
  > a[class^="carousel-control-"] {
    display: block;
    cursor: pointer;
    width: 40px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: center;
    opacity: 1;
    &:focus,
    &:hover {
      display: block;
      margin-top: auto;
      margin-bottom: auto;
      border-radius: 2px;
      outline: none !important;
    }
    span {
      opacity: 0;
    }
  }
  > a[class*="next"] {
    right: -30px;
    background-image: url("/assets/chevron_right.svg");
  }
  > a[class*="prev"] {
    left: -30px;
    background-image: url("/assets/chevron_left.svg");
  }

  @media (max-width: 768px) {
    a[class^="carousel-control-"] {
      border-radius: 50%;
    }

    a[class*="next"] {
      right: 10px;
    }

    a[class*="prev"] {
      left: 10px;
    }
  }

  @media (max-width: 480px) {
    a[class^="carousel-control-"] {
      border-radius: 50%;
  
    }

    a[class*="next"] {
      right: -7px;
    }

    a[class*="prev"] {
      left: -7px;
    }
  }
}
