.career {
  &-section {
    width: 100%;
    background-color: #ffdf80;
  }

  &-background-img {
    background-image: url("../../../public/assets/CareerBackground.svg");
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
  }

  &-title {
    color: #120540;
    font-size: 40px;
    font-family: "dm-sans-light", DMSans-Light, sans-serif;
    padding: 20px 0;
  }

  &-subtitle {
    color: #120540;
    font-size: 24px;
    font-family: "dm-sans-medium", DMSans-Medium, sans-serif;
    padding: 20px 30px;
  }

  &-text {
    color: #1c1c1c;
    font-size: 19px;
    font-family: "roboto", Roboto, sans-serif;
    padding: 10px 30px;
  }

  &-media-row-image,
  &-media-row-video {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 5px 5px 0 0;
    max-height: 300px;
  }
  &-media-row-video {
    height: 300px;
    display: block;

    @media (max-width: 1200px) {
      height: 250px;
    }
  }

  &-link-column {
    display: flex;
    justify-content: center;
  }

  &-link {
    text-decoration: none;
    font-size: 19px;
    color: #6b009a;
    padding: 15px 0;

    &:hover {
      color: #6b009a;
      text-decoration: none;
    }
  }

  &-comment {
    padding-top: 30px;
  }

  &-card-body {
    padding: 0;
  }
}
