// ISAAC
.content-summary-link .content-summary-link-title {
  background: none;
  border: 0;
  .text-secondary {
    font-family: $secondary-font-semi-bold;
  }
}

.new-tab {
  height: 24px;
  width: 24px;
}

.content-summary-link {
  a {
    text-decoration: none;
  }
  &.de-emphasised {
    background: rgba($gray-160, 0.09);
  }
}

.content-video-container {
  position: relative;
  padding-bottom: 56.25%;
  margin-bottom: 1rem;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    margin-bottom: 2rem;
  }
}

.email-preview-frame {
  width: 100%;
  height: 800px;
  border: solid thin #e3e3e3;
}

.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-title {
  text-align: center;
}

// NOMENSA login.scss
button.btn.btn-link.login-b,
.login a {
  color: $black;
  font-family: $primary-font;
  font-size: 0.875rem;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    @include respond-above(sm) {
      text-decoration: underline;
    }
  }
}

.login {
  display: inline-block;

  a {
    display: block;
  }
}

// NOMENSA signup.scss
.signup {
  display: none;

  @include respond-above(sm) {
    display: block;
  }

  a {
    color: $black;
    font-family: $primary-font;
    font-size: 0.875rem;
    font-weight: 400;
    text-decoration: none;
    text-transform: uppercase;
    display: block;

    &:hover {
      @include respond-above(sm) {
        text-decoration: underline;
      }
    }
  }
}

// ISAAC after final Nomensa delivery we can reduce duplication
.login a {
  color: $black;
  font-family: $primary-font;
  font-size: 0.875rem;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    @include respond-above(sm) {
      text-decoration: underline;
    }
  }

  span {
    border: 0;
    display: block;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    clip: initial;
    height: initial;
    position: relative;
    width: initial;
  }
}

.my-account a {
  color: $black;
  font-family: $primary-font;
  font-size: 0.875rem;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;
  position: initial !important;
  display: unset !important;

  &:hover {
    @include respond-above(sm) {
      text-decoration: underline;
    }
  }

  span {
    border: 0;
    display: block;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    clip: initial;
    height: initial;
    position: relative;
    width: initial;
  }
}

.logout {
  display: none;

  @include respond-above(sm) {
    display: inline-block;
  }

  a {
    color: $black;
    font-family: $primary-font;
    font-size: 0.875rem;
    font-weight: 400;
    text-decoration: none;
    text-transform: uppercase;
    display: block;

    &:hover {
      @include respond-above(sm) {
        text-decoration: underline;
      }
    }
  }
}

.hex-icon {
  width: 50px;
  height: 58px;
  background-repeat: no-repeat;
  z-index: 1;
  float: right;
  position: relative;
  margin-right: 5px;
  margin-left: 5px;
  background-color: transparent;
}

.share-link-icon {
  @extend .hex-icon;

  button {
    background-image: url(/assets/share.svg);
    background-repeat: no-repeat;
    z-index: 2;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: transparent;
  }

  .share-link {
    z-index: 1;
    font-family: $secondary-font !important;
    display: none;
    position: absolute;
    padding: 4px 32px 4px 8px;
    border: 1px solid gray;
    transform: translate(calc(25px - 100%), -65px);
    background: #fff;
    height: 58px;
    width: 70%;

    input {
      position: relative;
      top: 9px;
      white-space: nowrap;
      text-overflow: ellipsis;
      user-select: all;
      -webkit-user-select: all;
      -moz-user-select: all;
      -ms-user-select: all;
      width: 100%;
      padding-left: 2px;
      border-width: 1px;
    }

    &.double-height {
      height: 118px;
    }
  }
}

.print-icon {
  @extend .hex-icon;
  background-image: url(/assets/print.svg);
}
.not-mobile {
  @include respond-below(sm) {
    display: none;
  }
}

.report-icon {
  @extend .hex-icon;
  background-image: url(/assets/flag.svg);
}

.pointer-cursor {
  cursor: pointer;
}

.grab-cursor {
  cursor: grab;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.overflow-visible {
  overflow: visible !important;
}

iframe.email-html {
  border: 1px solid black;
  border-radius: 5px;
  width: 100%;
  height: content-box;
  background-color: #e9ecef;
  opacity: 1;
}

.larger-checkbox .custom-control-label::before,
.larger-checkbox .custom-control-label::after {
  transform: scale(2);
  margin-left: 0.5rem;
}

.larger-checkbox .custom-control-label {
  padding-left: 1rem;
}

.banner-secondary {
  color: white;
  background-color: #07174f;
  a {
    color: white;
  }
}

.banner-primary {
  background-color: $primary;
  color: black;
  button {
    background-color: black;
    color: white;
  }
}

// OLD NOMENSA TO ALTER INTO ISAAC
//a, button {
//  &.btn.btn-link.login-b {
//    color: $black;
//    font-family: $primary-font;
//    font-weight: 400;
//    text-decoration: underline;
//
//    &::before {
//      @include pseudo-element();
//      background-image: url('/assets/avatar.svg');
//      background-repeat: no-repeat;
//      background-size: contain;
//      height: 24px;
//      width: 24px;
//
//      @include respond-above(sm) {
//        display: none;
//      }
//    }
//
//    span {
//      border: 0;
//      clip: rect(0, 0, 0, 0);
//      display: block;
//      height: 1px;
//      overflow: hidden;
//      padding: 0;
//      position: absolute;
//      white-space: nowrap;
//      width: 1px;
//
//
//      @include respond-above(sm) {
//        clip: initial;
//        height: initial;
//        position: relative;
//        width: initial;
//      }
//    }
//  }
//}
