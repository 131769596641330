$highlight-text-colour: #ffffff;

.hi-pink {
  background-color: $highlight-pink;
  color: $highlight-text-colour;
}

.hi-pink-50 {
  background-color: $highlight-pink-50;
  color: $highlight-text-colour;
}

.hi-pink-25 {
  background-color: $highlight-pink-25;
}

.hi-navy {
  background-color: $highlight-navy;
  color: $highlight-text-colour;
}

.hi-navy-50 {
  background-color: $highlight-navy-50;
  color: $highlight-text-colour;
}

.hi-navy-25 {
  background-color: $highlight-navy-25;
}

.hi-teal {
  background-color: $highlight-teal;
  color: $highlight-text-colour;
}

.hi-teal-50 {
  background-color: $highlight-teal-50;
  color: $highlight-text-colour;
}

.hi-teal-25 {
  background-color: $highlight-teal-25;
}

.hi-mustard {
  background-color: $highlight-mustard;
  color: $highlight-text-colour;
}

.hi-mustard-50 {
  background-color: $highlight-mustard-50;
  color: $highlight-text-colour;
}

.hi-mustard-25 {
  background-color: $highlight-mustard-25;
}
