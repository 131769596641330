// ----------------------------------------------
// NOMENSA variables.scss
//Custom Colours
// ----------------------------------------------
$cs-mustard: #ffc000;
$cs-hot-pink: #ff4aa0;
$cs-reddish: #d7335f; // Used as an active colour on secondary buttons
$focus-blue: #3e59a3; // Used as focus indicator colour on inputs

// ----------------------------------------------
// Colour Overrides
// ----------------------------------------------
$purple: #9148bc;
$cyan: #0f8294;
$green: #208838;

// ----------------------------------------------
// Theme
// ----------------------------------------------
$primary: $cs-mustard;
$secondary: $cs-hot-pink;
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
);

// ----------------------------------------------
// Stage Colours
// ----------------------------------------------
$gcse-colour: #c40e50;
$gcse-colour-50: #e186a7;
$gcse-colour-25: #f0c3d3;
$all-stages-colour: #9148bc;
$all-stages-colour-50: #c8a3dd;
$all-stages-colour-25: #e3d1ee;
$a-level-colour: #2146ad;
$a-level-colour-50: #90a2d6;
$a-level-colour-25: #c7d1ea;

// ----------------------------------------------
// Highlighting Colours
// ----------------------------------------------
$highlight-pink: #e72c62;
$highlight-pink-50: #f395b0;
$highlight-pink-25: #f9cad8;
$highlight-navy: #07174f;
$highlight-navy-50: #838ba7;
$highlight-navy-25: #c1c5d3;
$highlight-teal: #0f8294;
$highlight-teal-50: #87c0c9;
$highlight-teal-25: #c3e0e4;
$highlight-mustard: #e09220;
$highlight-mustard-50: #efc88f;
$highlight-mustard-25: #f7e4c7;

// ----------------------------------------------
// Greys
// ----------------------------------------------
$gray-103: #f8f8f8;
$gray-107: #ececec;
$gray-110: #e7e7e7;
$gray-118: #d2d2d2;
$gray-120: #c9cad1;
$gray-136: #a3a3a3;
$gray-142: #949494;
$gray-160: #666;
$gray-194: #0f0f0f;
$gray-blue: #636c73;

// ----------------------------------------------
// Shadows
// ----------------------------------------------
$shadow-3: rgba(0, 0, 0, 0.3);
$shadow-25: rgba(0, 0, 0, 0.25);
$shadow-08: rgba(0, 0, 0, 0.08);
$shadow-black: rgb(0, 0, 0);

// ----------------------------------------------
// Button Sizing
// ----------------------------------------------
$input-btn-padding-y-lg: 0.5rem;
$input-btn-padding-x-lg: 3rem;
$border-radius-lg: 0.25rem;

// ----------------------------------------------
// Fonts
// ----------------------------------------------
$primary-font: Arial, "roboto", Roboto;
$secondary-font: "roboto", Roboto;
$secondary-font-bold: "roboto-700", Roboto-Bold;
$secondary-font-semi-bold: "dm-sans-medium", DMSans-Medium;
$secondary-font-medium: "dm-sans-medium", DMSans-Medium;
$tertiary-font: "roboto", Roboto;
$homepage-text-font: "roboto", Roboto;
$homepage-title-font: "dm-sans-light", DMSans-Light;
$homepage-button-font: "dm-sans-medium", DMSans-Medium;
$footer-text-font: "roboto", Roboto;
$footer-bold-font: "roboto-700", Roboto-Bold;
$footer-links-font: "dm-sans-700", DMSans-Bold;

// ----------------------------------------------
// ISAAC
// Node module imports
// ----------------------------------------------
@import "~bootstrap/scss/bootstrap";
@import "~katex/dist/katex.min.css";
@import "~bootstrap-block-grid/dist/bootstrap4-block-grid.min.css";
@import "~billboard.js/dist/theme/insight.css";
@import "~highlight.js/styles/a11y-light.css";
@import "~leaflet/dist/leaflet.css";
@import "../common/isaac";

// ----------------------------------------------
// Atoms
// ----------------------------------------------
@import "preload";
@import "../common/mixins";
@import "../common/animations";

// ----------------------------------------------
// Atoms
// ----------------------------------------------
@import "../common/fonts";
@import "../common/typography";
@import "../common/headings";
@import "../common/modals";
@import "questions";
@import "../common/forms";
@import "../common/elements";
@import "../common/breadcrumbs";
@import "../common/media";
@import "../common/tabs";
@import "../common/accordions";
@import "../common/cards";
@import "../common/list-groups";
@import "inequality-modal";
@import "../common/carousel";
@import "../common/icons";
@import "../common/boards";
@import "../common/focus";
@import "../common/progress-bar";
@import "../common/anvil";
@import "../common/filter";
@import "../common/reservation-modal";
@import "../common/scroll";
@import "button";
@import "logo";
@import "featured-profile";
@import "../common/careers";
@import "../common/code";
@import "question-finder";

// ----------------------------------------------
// Navigation
// ----------------------------------------------
@import "header";
@import "../common/navigation-bar";
@import "../common/footer";

// ----------------------------------------------
// Pages
// ----------------------------------------------
@import "../common/pages";
@import "homepage";
@import "../common/login";
@import "../common/registration";
@import "../common/assignments";
@import "../common/my-account";
@import "../common/topic";
@import "../common/search";
@import "../common/my-progress";
@import "../common/gameboard";
@import "../common/assignment-progress";
@import "../common/groups";
@import "../common/admin";
@import "../common/events";
@import "../common/glossary";
@import "../common/quiz";
@import "accordions";
@import "../common/callout";

@import "parsons-layout";

// ----------------------------------------------
// Print
// ----------------------------------------------
@import "../common/print";

// ----------------------------------------------
// Difficulty Icons
// ----------------------------------------------
@import "filter";

// ----------------------------------------------
//  Highlights
// ----------------------------------------------
@import "highlight";

// ----------------------------------------------
// Link Styles
// ----------------------------------------------
a.disabled {
  color: $gray-600;
  pointer-events: none;
  cursor: default;
}

a.btn {
  font-family: "dm-sans-medium", DMSans-Medium, sans-serif;
}

.btn {
  text-decoration: none;

  &:hover {
    text-decoration: none; // Maintain no underline on hover
  }
}

// ----------------------------------------------
// Button Styles
// ----------------------------------------------
button {
  font-family: "dm-sans-medium", DMSans-Medium, sans-serif;

  &.btn-secondary,
  &.log-in {
    font-family: "dm-sans-medium", DMSans-Medium, sans-serif;
  }

  &.btn-primary,
  &.position-relative,
  #sign-up,
  .btn-block,
  .btn-xl {
    font-family: "dm-sans-light", DMSans-Light, sans-serif;
  }
}

.primary-button {
  background-color: #d60265;
  font-size: 18px;

  &:hover {
    background-color: #ad0051;
    text-decoration: underline;
  }
}

// ----------------------------------------------
// Span Styles
// ----------------------------------------------
.signup span,
.login span,
.logout span,
.my-account span {
  text-transform: none;
  font-family: "dm-sans-medium", DMSans-Medium, sans-serif;
  font-weight: 500;
  font-size: 18px;
}
@media (max-width: 576px) {
  .signup {
    margin-right: 2rem; /* Adjust as needed */
    margin-left: 1rem; /* Adjust as needed */
  }

  .signup span,
  .login span,
  .logout span,
  .my-account span {
    font-size: 16px;
  }
}

// ----------------------------------------------
// SVG Icon Styles
// ----------------------------------------------
.svgIcon {
  margin-left: 8px;
  width: 14px;
  height: 8.25px;
}

// ----------------------------------------------
// Search Text Styles
// ----------------------------------------------
.search-text {
  color: #fff;
  font-size: 16px;
  margin-top: 2px;
  padding: 8px 6px;
  font-family: "dm-sans-medium", DMSans-Medium, sans-serif;
}

// ----------------------------------------------
// List Group Item Styles
// ----------------------------------------------
.list-group-item {
  border-width: 0;
  padding: 12px 16px;
}

p small {
  border: 0;
}

// ----------------------------------------------
// Background Styles
// ----------------------------------------------
.bg-light-grey {
  > div {
    background: rgba(#f7f7f7, 0.9);
    position: relative;
    z-index: 1;
  }
}

// ----------------------------------------------
// Katex Styles
// ----------------------------------------------
.katex.katex {
  font-size: 1.1em;
}

// ----------------------------------------------
// Body Styles
// ----------------------------------------------
body {
  color: $black;
  font-family: "roboto", Roboto, sans-serif;
  line-height: 1.625;
  display: flex;
  flex-direction: column;
}

// ----------------------------------------------
// Heading Styles
// ----------------------------------------------
h1,
h2.h-title {
  font-family: "dm-sans-light", DMSans-Light, sans-serif;
}

.primary-heading {
  font-family: "dm-sans-light", DMSans-Light, sans-serif;
  font-size: 48px;
  color: #120540;
}

.homepage-sub-title {
  font-family: "dm-sans-light", DMSans-Light, sans-serif;
  font-size: 48px;
  color: #120540;
}

// ----------------------------------------------
// Content Body Styles
// ----------------------------------------------
.content-body {
  background-color: rgba($gray-107, 0.4);
}

// ----------------------------------------------
// Text Styles
// ----------------------------------------------
.body-text {
  font-size: 19px;
}

// ----------------------------------------------
// Pattern Styles
// ----------------------------------------------
.pattern-01 {
  background-image: url("/assets/pattern_sm.svg");
  background-position: left top;
  background-repeat: repeat;

  @include respond-above(md) {
    background-image: none;
  }

  > .container {
    @include respond-above(md) {
      background-image: url("/assets/pattern01a.svg");
      background-position: left top;
      background-repeat: repeat-y;
    }
  }
}

// ----------------------------------------------
// Custom width styling
// ----------------------------------------------
.custom-width {
  max-width: 50%;
  flex: 45%;
  padding-right: 5px;
}

// Additional pattern styles...
.pattern-02 {
  position: relative;

  &:after,
  &:before {
    @include pseudo-element;
    background-image: url("/assets/pattern01.svg");
    background-position: 0 0;
    background-repeat: no-repeat;
    display: none;
    width: 600px;
    height: 600px;
    z-index: 0;
  }

  &:after {
    @include respond-above(sm) {
      display: block;
      right: -9%;
      top: 250px;
    }
  }

  &:before {
    @include respond-above(sm) {
      display: block;
      left: -9%;
      top: 800px;
    }
  }
}

// Other patterns remain unchanged...

// ----------------------------------------------
// Spinner Styles
// ----------------------------------------------
.isaac-spinner-sm {
  width: 1.5rem;
  height: 1.5rem;
}

.isaac-spinner-md {
  width: 2.5rem;
  height: 2.5rem;
}

.isaac-spinner-lg {
  width: 3.5rem;
  height: 3.5rem;
}

// ----------------------------------------------
// Homepage Event Section
// ----------------------------------------------

.event-section {
  background-color: #ffbbdc;
}
.event-section-background-img {
  background-image: url("../../../public/assets/EventBackground.svg");
  background-repeat: no-repeat; // Optional: Prevent the image from repeating
  background-position: right; // Center the image
  background-size: contain;
}

.event-text-description {
  font-size: 19px;
  color: #1c1c1c;
  padding-left: 0;
}

.custom-card {
  width: 322px;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  box-shadow: 0 2px 10px rgba(255, 192, 0, 0.5);
  border-radius: 5px;
}

.course-name {
  font-size: 24px;
  font-family: "dm-sans-light", DMSans-Light, sans-serif;
  background-color: #f6b400;
  padding-top: 9px;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 9px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.card-title {
  font-size: 20px;
  font-family: "dm-sans-light", DMSans-Light, sans-serif;
  color: #120540;
}

.card-sub-title {
  font-size: 16px;
  font-family: "roboto-700", Roboto-Bold, sans-serif;
  color: #1c1c1c;
}

.details-button {
  text-decoration: none;
  text-align: center;
  font-size: 19px !important;
  color: #6b009a;
  flex: 1;
}

.details-button:hover {
  color: #6b009a;
  text-decoration: underline;
}

a.browse-events {
  color: #0f0f0f;
  font-family: "dm-sans-light", DMSans-Light, sans-serif;
  font-size: 18px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 3px solid #d60265;
  border-radius: 5px;
  width: 210px;
  height: 48px;

  &:hover {
    text-decoration: underline;
    border: 3px solid #AD0051;
  }
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
}
