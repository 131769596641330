.leaflet-container {
  width: 100%;
  aspect-ratio: 1;
}

// If browser supports overflow: clip, use it because it prevents programmatic scrolling as well. This is important
// for div#root, and fixes an issue with dnd-kit causing this element to scroll unexpectedly.
@supports (overflow: clip) {
  /* safe to use overflow: clip */
  .overflow-clip {
    overflow: clip !important;
  }
}
@supports not (overflow: clip) {
  /* default to overflow: hidden */
  .overflow-clip {
    overflow: hidden !important;
  }
}
