// NOMENSA list-group-lists.scss AND list-group-topics.scss
// REPLACE ./assets WITH /assets
.link-list {
  a {
    font-family: $secondary-font-medium;
  }
  .eventList & {
    background-color: transparent;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &::-webkit-scrollbar {
      display: none;
    }

    @include respond-above(xs) {
      flex-wrap: wrap;
      overflow-x: initial;
    }

    .list-group-item {
      background-color: transparent;
      flex: 1 0 87%;
      padding: 0;

      @include respond-above(xs) {
        flex: 1;
      }
    }
  }
}

.eventList {
  > * {
    background-color: transparent;
  }

  > a {
    display: block;
    font-family: $primary-font;
    font-size: 1.125rem;
    font-weight: bold;
    padding: 0 1rem;
  }
}

.link-list-list {
  list-style-position: outside;
  margin-left: 1rem;

  li {
    display: list-item;
    line-height: 2;
  }
}

.teachers-page,
.students-page {
  .row:first-of-type {
    ul {
      background-color: $gray-107;
      margin: 0 auto;
      width: 100%;
      @include respond-above(md) {
        background-color: transparent;
      }

      &.list-group {
        .list-group-item {
          @include respond-above(sm) {
            flex: 1 1 33%;
          }
          @include respond-above(md) {
            flex: 0 1 33%;
          }
          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            flex: 0 1 27%;
          }
        }
      }
    }
  }
}

// NOMENSA list-group-topic.scss
.link-list {
  a {
    font-family: $secondary-font-medium;
  }
}

.list-group-links {
  background-color: $white;
  border: 1px solid $gray-107;
  .bg-success {
    background-color: #dfefd7 !important;
    a > span:first-of-type {
      border-color: #ddd;
    }
  }

  li {
    border-bottom: 1px solid $gray-107;

    &.list-group-category {
      a {
        color: $purple;
      }
    }

    a {
      display: flex;
      font-size: 1.25rem;
      text-decoration: none;
      padding-right: 38px;
      position: relative;

      > span:first-of-type {
        display: inline-block;
        border-right: 1px solid $gray-107;
        margin-right: 1rem;
        padding-right: 1rem;
        text-align: center;
        min-width: 3rem;
      }

      &:after {
        @include pseudo-element;
        background-image: url("/assets/chevron_right.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: center;
        height: 100%;
        right: 10px;
        top: 0;
        bottom: 0;
        width: 20px;
      }
    }

    &:last-of-type {
      border-width: 0;
    }
  }
}
