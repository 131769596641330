#homepage {
  //section {padding: 2rem 0;}

  h1#homepageName {
    text-shadow:
      0 0 4px $gray-103,
      0 0 5px $gray-103,
      0 0 10px $gray-103,
      0 0 10px $gray-103,
      0 0 20px $gray-103;
  }

  img#homepageHeroImg {
    left: 50%;
    transform: translateX(-50%);
    bottom: -15%;
    z-index: -1;
    position: absolute;
    @include media-breakpoint-only(sm) {
      margin-bottom: -50%;
    }
    opacity: 0.6;
  }

  //#homepageButtons {
  //  @include media-breakpoint-only(sm) {
  //    padding-top: 100px;
  //    padding-right: 35px;
  //    width: calc(100% + 20px);
  //    background-image: linear-gradient(0, rgba(248,248,248,1) 50%, rgba(0,0,0,0) 100%);
  //  }
  //}

  section#why-sign-up {
    //h1 {padding-bottom: 2rem;}
    //.card {
    //  box-shadow: 0 2px 30px 0 $shadow-25;
    //}
    //img {
    //  //border: 1px solid $gray-107;
    //  box-shadow: 0 2px 30px 0 $shadow-25;
    //}
  }

  section#headline-content {
    //background-color: $primary;
    //background-image: url(/assets/pattern01.svg);
    //background-repeat: repeat-x;
    //background-size: 100%;
    //background-blend-mode: soft-light;
    pre {
      background-color: rgba(white, 0.1);
      box-shadow: 0 2px 30px 0 $shadow-08;
    }
    // TODO FEATURED_QUESTION_UPDATE remove the .multichoice-question rule on next featured question change
    .multichoice-question > ul li {
      margin-left: -1.5rem !important;
    }

    .question-component {
      background: white;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      .validation-response-panel.correct {
        background: rgba($primary, 0.95);
        color: black;
        border-bottom: none;
      }
      @include media-breakpoint-up(md) {
        img {
          max-width: 50%;
          padding: 0;
        }
      }
      .figure-caption {
        display: none; // TODO FEATURED_QUESTION_UPDATE this might not be appropriate for other questions
      }
    }

    .computer-scientist-of-the-month {
      @include media-breakpoint-up(md) {
        .profile-image {
          margin-right: 1.5rem;
          float: left;
        }
        .featured-profile {
          height: auto;
          width: auto;
          float: none;
        }
      }
      @include media-breakpoint-down(sm) {
        .profile-image {
          max-width: 100%;
          height: auto;
        }
      }
      .profile-image {
        width: auto;
      }
      .profile-titles {
        @extend .mt-4;
      }
      .profile-title {
        @extend .h5;
      }
      .profile-subtitle {
        @extend .font-weight-bold;
      }
      .profile-description {
        font-size: inherit;
        color: inherit;
      }
      .profile-title,
      .profile-subtitle,
      .profile-description {
        @extend .text-left;
      }
    }
  }

  .teacher-button {
    background-color: $highlight-navy;
    color: white;
    border-color: $highlight-navy;
  }
}

// ----------------------------------------------
// Homepage Resources Section
// ----------------------------------------------

.resources {
  &-section {
    background-color: white;
  }

  &-background {
    background-color: #ffdf80;
    max-width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: 5px;
  }

  &-heading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-sub-heading {
    color: #120540;
    font-family: "dm-sans-medium", DMSans-Medium, sans-serif;
    font-size: 24px;
  }

  &-text-description {
    font-size: 19px;
    color: #1c1c1c;
    padding-left: 0;
    flex: 1;
  }

  &-links {
    color: #0f0f0f;
    font-family: "dm-sans-light", DMSans-Light, sans-serif;
    font-size: 18px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 3px solid #d60265;
    border-radius: 5px;
    width: 230px;
    height: 48px;
    box-sizing: border-box;

    @media (max-width: 768px) {
      margin-bottom: 10px;
    }

    &:hover {
      text-decoration: underline;
      border: 3px solid #ad0051;
    }
  }

  &-tooltip-container {
    position: relative;
    display: inline-block;

    .tooltip-text {
      visibility: hidden;
      width: 270px;
      background-color: #333;
      color: #fff;
      text-align: center;
      border-radius: 5px;
      position: absolute;
      z-index: 1;
      bottom: 125%;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
      transition: opacity 0.3s;
    }

    &:hover .tooltip-text {
      visibility: visible;
      opacity: 1;
    }
  }

  &-columns-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &-custom-col-60 {
    flex: 0 0 66%;
    max-width: 66%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      flex: 100%;
      max-width: 100%;
    }
    .resources-center-container {
      display: flex;
      flex-direction: column;

      @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  &-custom-col-30 {
    flex: 0 0 33%;
    max-width: 33%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      flex: 100%;
      max-width: 100%;
    }
  }

  &-center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 10px;
    }
  }

  &-comment {
    display: flex;
    align-items: center;
    gap: 2px;

    &-content {
      background-color: #fff5fa;
      color: #120540;
      font-size: 20px;
      font-family: "dm-sans-light", DMSans-Light, sans-serif;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 20px;
      text-align: center;

      .star-img {
        width: 26px;
        height: 25px;
      }
    }
  }
}

// ----------------------------------------------
// Homepage Highlight Section
// ----------------------------------------------

.homepage-highlight {
  background-color: #ffdf80;

  &-title {
    font-size: 78px;
    color: #120540;
  }

  &-sub-title {
    color: #120540;
    font-size: 32px;
  }
}
