// ISAAC

@import "mixins";

.banner {
  background: rgba($gray-blue, 0.1);
  box-shadow: 0 2px 30px 0 $shadow-08;
  z-index: 1;
  border-top: solid 1px rgba($gray-blue, 0.6);
  border-bottom: solid 1px rgba($gray-blue, 0.6);
}
.banner-button {
  @include respond-below(sm) {
    width: 100%;
  }
  @include respond-below(lg) {
    min-width: 150px;
  }
  //border-color: $purple !important;
  //color: $purple !important;
}

.toasts-container {
  position: fixed;
  top: 98px;
  right: 98px;
  z-index: 10000;

  .fade {
    transition:
      opacity 0.4s linear,
      max-height 0.3s ease-out,
      margin-bottom 0.3s linear,
      border-width 0.3s linear;
    max-height: 200px;
  }
  .toast:not(.show) {
    max-height: 0;
    margin-bottom: 0;
    border: none;
  }
}

.toast {
  max-width: unset;
  background-color: rgba(255, 255, 255, 0.92);
}
.toast-header {
  font-size: 1.6rem;
}
.toast-heading {
  padding-right: 1rem;
}
.toast-body {
  font-size: 1rem;
}

header {
  box-shadow: 0 2px 30px 0 $shadow-08;
}

.title-edit {
  input[type="text"] {
    height: 50px;
    min-width: 350px;
  }
  button {
    height: 30px;
    width: 50px;
    min-width: 50px !important;
  }
}

.title-help {
  display: flex;
  align-items: center;
  cursor: help;
  font-size: 1.25rem !important;
  line-height: 1.2 !important;
  min-height: 50px !important;
}

.title-help-modal {
  cursor: pointer !important;
}

.h-subtitle {
  padding-top: 5px;
  margin-bottom: -5px;
  font-size: 1.25rem;
  line-height: 1.2;
}

a.skip-main {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
}
a.skip-main:focus,
a.skip-main:active {
  color: #000;
  background-color: #fff;
  left: 5%;
  top: 60px;
  width: 175px;
  height: auto;
  overflow: auto;
  margin: 10px;
  text-align: center;
  font-family: $secondary-font-medium;
  font-size: 1rem;
  z-index: 999;
}

// reverse final element in the navigation bar to keep options on the screen
ul#main-menu {
  li.dropdown.nav-item:last-child {
    div.dropdown-menu {
      right: 0;
      left: auto;
      &::before {
        right: 1rem;
        left: auto;
      }
    }
  }
}

// NOMENSA header.scss
// !important used to override bootstraps use of !important :(

header {
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;

  > .container {
    @include respond-below(sm) {
      max-width: 100% !important;
    }
  }

  .header-bar {
    position: relative;
    padding-bottom: 1.5rem !important;
    @include clearfix;

    @include respond-above(sm) {
      padding-right: auto;
    }
  }

  .header-links {
    justify-content: flex-end !important;

    @include respond-below(xs) {
      padding-right: 20% !important;
    }
    @include respond-above(xs) {
      padding-right: 28% !important;
    }
    @include respond-above(sm) {
      margin-top: 0.2rem;
      padding-right: 0 !important;
      width: 100%;
    }
  }
}
