// NOMENSA footer.scss
// REPLACE ./assets WITH /assets
.footerTop {
  background-color: #120540;
  color: white;
}

.footer-links {
  color: white;

  .footer-support-links {
    .h5 {
      font-family: $footer-links-font;
    }

    .link-list {

      a {
        font-family: $footer-text-font;
        color: white;
        text-decoration: none;

        &:hover {
          color: white;
        }
      }
    }
  }

  .h5 {
    font-family: $footer-links-font;
  }

  a {
    color: white;
    display: block;
    text-decoration: none;

    &:hover {
      color: white;
    }
  }

  .footerLink {
    font-family: $footer-text-font;
    font-size: 16px;
  }

  .logo-col {
    @include respond-below(sm) {
      padding: 1rem;
    }
    a {
      color: white;
      display: block;
      text-decoration: none;

      &:hover {
        color: white;
      }
    }
  }
  &.footer-links-social {
    background-position: left 82%;
    background-repeat: no-repeat;
    height: 100%;
    

    @include respond-above(md) {
      background-position: left 100% bottom 15%;
    }
  }
}

.logo-text {
  color: white;
  font-family: $footer-text-font;
  font-size: 14px;
  line-height: 16.41px;
}

.link-list {
  a {
    font-family: $footer-text-font;
  }
}

.footer-bottom {
  background-color: white;
  width: 100%;

  .h5 {
    color: black;
    font-family: $footer-links-font;
    font-size: 20px;
  }

  .footer-bottom-info {
    p {
      font-family: $footer-text-font;
      font-size: 14px;
      color: #000000;
    }
    @include respond-above(md) {
      width: 40%;
    }
  }
  .footer-bottom-logos {
    @include respond-above(md) {
      width: 30%;
      margin-left: 8%;
    }
  }
}

.footer-bottom-links a {
  color: black;
  padding-right: 30px;
  
  &:hover {
    color: black;
  }
}

.print-font {
  font-family: $footer-bold-font;
  color: black !important;
}

.footer-bottom-logos img {
  max-width: 100%;
  height: auto;
}

@media only screen and (min-width: 990px) {
  .footer-bottom-logos img {
    height: auto;
    width: auto;
    max-width: 90%;
    padding-left: 14px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 989px) {
  .links-col {
    padding-top: 0.5rem !important;
  }

  .footer-bottom-info {
    p {
      padding-bottom: 1.5rem !important;
    }
  }

  .footer-bottom-logos {
    padding-bottom: 1.5rem;
  }
  
  .footer-bottom-logos img {
    height: auto;
    width: auto;
    max-width: 90%;
    padding-left: 14px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 768px) {
  .links-col {
    padding-top: 0.5rem !important;
  }

  .footer-links .footer-support-links .h5, 
  .footer-bottom-links .h5 {
    margin-bottom: 15px;
    text-align: left;
  }
  
  .footer-links .footer-support-links a,
  .footer-bottom-links a {
    margin-bottom: 10px;
  }

  .footer-bottom-info {
    p {
      padding-bottom: 1.5rem !important;
    }
  }

  .footer-bottom-logos {
    padding-bottom: 1.5rem;
    margin-left: 5%;
    gap: 1.25rem;
  }

}

@media only screen and (max-width: 480px) {
  .logo-col {
    padding: 20px !important;
  }
  
  .links-col {
    padding-top: 0.5rem !important;
  }

  .footer-links .footer-support-links .h5, 
  .footer-bottom-links .h5 {
    margin-bottom: 15px;
    text-align: left;
  }
  
  .footer-links .footer-support-links a,
  .footer-bottom-links a {
    margin-bottom: 10px;
  }

  .footer-bottom-logos {
    gap: 1.25rem;
    margin-left: 5%;
  }
}