.ioe {
  &-col {
    background-color: white;
  }

  &-title {
    color: #120540;
    font-size: 24px;
  }

  &-text {
    color: #1c1c1c;
    font-size: 19px;
  }
}

.accordion {
  &-button {
    background-color: white;
    color: #130019;
    border-bottom: 0px;
    font-size: 20px;
    font-family: "dm-sans-medium", DMSans-Medium, sans-serif;
  }

  &-body {
    font-size: 19px;
    color: #181818;
  }
}
#competition-information-section {
  background-color: #ffffff;
}

.competition-information {
  &-title {
    color: #120540;
    font-size: 24px;
  }

  &-text {
    color: #1c1c1c;
    font-size: 19px;
  }

  &-default-background {
    background-color: #f9f9f9;
  }

  &-prizes-background {
    background-color: #ffdf80;
  }

  &-no-border {
    border: none;
  }
}

.competition-timeline {
  background-color: #ffffff;

  &-title {
    color: #120540;
    font-size: 24px;
  }

  &-content {
    color: #130019;
    font-size: 19px;
  }

  &-header {
    color: #120540;
    font-size: 28px;
  }

  &-date {
    font-size: 20px;

    @media (max-width: 991px) {
      text-align: right;
    }
  }

  &-background-1 {
    background-color: #f9f9f9;
  }

  &-background-2 {
    background-color: #ffbbdc;
  }

  &-background-3 {
    background-color: #ffdf80;
  }

  &-background-4 {
    background-color: #ffc000;
  }

  &-box-container {
    @media (min-width: 1000px) {
      margin-right: 15px;
      max-width: calc(25% - 15px);
    }
    @media (max-width: 991px) {
      margin-bottom: 15px;
      max-width: none;
    }
  }

  &-arrow {
    width: 110px;
    height: 35px;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 25px solid;
    position: absolute;
    top: 50%;
    right: -110px;
    transform: translateY(-50%);
    z-index: 1;

    @media (max-width: 991px) {
      top: auto;
      bottom: -95px;
      right: 50%;
      transform: translateX(50%) rotate(90deg);
    }
  }

  &-background-1 &-arrow {
    border-left-color: #f9f9f9;
  }

  &-background-2 &-arrow {
    border-left-color: #ffbbdc;
  }

  &-background-3 &-arrow {
    border-left-color: #ffdf80;
  }

  &-background-4 &-arrow {
    border-left-color: #ffc000;
  }
}

.entry-form {
  &-section {
    background-color: #ffdf80;
  }

  &-background-img {
    background-image: url("../../../public/assets/CompetitionTrianglePattern.svg");
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
  }

  &-title {
    color: #120540;
    font-size: 36px;
  }

  &-sub-title {
    color: #130019;
    font-size: 18px;
    font-family: "dm-sans-light", DMSans-Light, sans-serif;
  }

  &-button {
    background-color: #d60265;
    color: white !important;
    border: none !important;
    width: 109px;
    height: 48px;
    font-size: 18px;
    font-family: "dm-sans-light", DMSans-Light, sans-serif;
  }

  &-button-label {
    padding-top: 34px;
  }

  &-button:focus {
    background-color: #d60265;
  }

  &-astrisk{
    color: red;
  }
}
