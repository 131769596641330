.board-subject-hexagon {
  background: url(/assets/hexagons_sprite.png) no-repeat -256px 0;
}

.subject-complete {
  background-position: -320px 0;
}

tr.board-card {
  height: 100px;
}
.col > .board-card {
  height: calc(100% - 25px);
}
.board-card {
  min-width: 30%;
  @media (max-width: map-get($breakpoints, sm)) {
    min-width: 50%;
  }
  margin-top: 25px;
  .card-footer {
    background: white;
    border-top: unset;
  }
  .card-body > .close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    min-width: 20px;
    padding-left: 1px;
  }
  aside {
    position: relative;
    margin-left: 100px;
    margin-top: 14px;
    margin-bottom: 12px;
    .card-subtitle {
      margin-top: 4px;
    }
  }
  .card-title {
    font-size: 1.5rem;
    font-weight: bold;
    a {
      display: inline;
    }
  }
  .card-subtitle {
    margin-bottom: 0.25rem;
  }
  button {
    -webkit-appearance: none;
    border: none;
    outline: none !important;
  }
  .unassign {
    cursor: pointer;
    min-width: 20px;
  }

  button.board-subject-hexagon-container {
    background-color: white;
  }
  // Subject hexagons:
  .board-subject-hexagon-container {
    position: absolute;
    width: 63px;

    &.table-view {
      position: relative;
      top: -5px;
    }
  }
  .board-subject-hexagon {
    position: absolute;
    top: 5px;
    left: 10px;
    width: 63px;
    height: 74px;

    &.z0 {
      z-index: 5;
    }
    &.z1 {
      z-index: 4;
      top: 10px;
      left: 5px;
    }
    &.z2 {
      z-index: 3;
      top: 10px;
      left: 15px;
    }
    &.z3 {
      z-index: 2;
      top: 0;
      left: 10px;
    }
  }
  .board-percent-completed {
    width: 100%;
    font-size: 30px;
    line-height: 110%;
    font-family: $secondary-font-semi-bold;
    color: #fff;
    z-index: 10;
    position: absolute;
    top: 25px;
    left: 15px;
    text-align: center;
    &:after {
      font-size: 0.5em;
      content: "%";
      position: relative;
      top: -15px;
    }
  }
  // If compsci uses yellow hexagons, need black number for accessibility:
  //.subject-compsci + .board-percent-completed {
  //  color: #0f0f0f;
  //}
  .groups-assigned {
    width: 100%;
    font-size: 12pt;
    line-height: 110%;
    font-weight: bold;
    z-index: 10;
    position: absolute;
    top: 28px;
    left: 0.7rem;
    color: #fff;
    text-align: center;
    strong {
      display: block;
      font-size: 20pt;
      font-weight: 600;
      padding-bottom: 4px;
      margin-top: -6px;
    }
  }
}

.input-align {
  @media only screen and (min-resolution: 192dpi) {
    width: 100%;
    align-self: center;
  }
}

.table-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-weight: bold;
}

.input-options {
  float: right;
  @media only screen and (max-width: 800px) {
    float: unset !important;
    flex-direction: column;
  }
}

.delete-button {
  top: -50px;
}

.table-share-link,
.card-share-link {
  position: relative;
}
