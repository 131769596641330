// NOMENSA cards.scss
// REPLACE ./assets with /assets
.card-neat {
  background-color: $white;
  border-radius: 0;
  border-width: 0;
  box-shadow: 0 2px 13px 0 $shadow-08;
  width: 100%;

  @include respond-above(sm) {
    box-shadow: 0 2px 30px 0 $shadow-08;
  }
  .news-card-image {
    @include aspect-ratio(100, 59);
  }
  // Nomensa's image offset code, if we decide to use
  //.news-card-image {
  //  //margin-left: -1.45rem;
  //  //width: calc(100% - 0.5rem) !important;
  //}
  .event-card-image {
    img {
      display: block;
      height: 180px;
      max-width: 320px;
      padding-top: 8px;
    }
  }

  .card-title {
    font-family: $secondary-font-semi-bold;
    font-size: 1.25rem;
  }

  .card-body {
    .card-text {
      > a {
        font-size: 1.125rem;
        font-weight: bold;
      }
      &.card-text01 {
        flex: 1 0 auto;
      }
      &.card-date-time {
        flex: 1 0 auto;
      }
    }
  }

  &.disabled {
    img {
      filter: grayscale(100%);
      -webkit-filter: grayscale(100%);
    }
  }
}

.question-tile {
  max-width: 100%;
  border: solid 1px #f8f8f8;
  margin: 1.5rem 1rem 1rem 1rem;
  img {
    max-width: 70%;
  }
}

.next-question {
  display: flex;
  align-self: baseline;
  margin-left: auto;
  padding: 0 !important;
  font-family: $secondary-font !important;
}

.random-question-panel.isaac-expand-bg {
  overflow: hidden;
}

.random-question-panel {
  @include media-breakpoint-up(sm) {
    max-width: 750px;
    img {
      max-width: 90%;
    }
  }

  @include media-breakpoint-up(md) {
    max-width: 800px;
    img {
      max-width: 70%;
    }
  }

  @include media-breakpoint-up(lg) {
    max-width: 1140px;
    img {
      max-width: 50%;
    }
  }
}

.hidden-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

@include media-breakpoint-up(md) {
  .question-tile,
  .random-question-panel {
    .parsons-layout {
      width: 100%;
      @include make-col-offset(0, 0);
    }
  }
}

.question-tile,
.random-question-panel {
  .question-component {
    box-shadow: none;
  }
}

.featured-news-item {
  background: #dddddd55;
  max-width: 350px;

  @media (max-width: 768px) {
    background: none;
  }
}

.sign-up-tabs {
  .tab-pane {
    .signtab-image {
      display: none;

      @include respond-above(md) {
        display: block;
        position: relative;
        z-index: 2;
      }

      img {
        max-width: 380px;
        width: 100%;
      }
    }

    .card {
      border-radius: 0;
      box-shadow: 0 2px 30px 0 $shadow-08;
      max-width: 380px;
      margin: 0 auto;
      position: relative;
      transform-style: preserve-3d;
      z-index: 1;

      @include respond-above(md) {
        &:before {
          @include pseudo-element;
          border: solid 3px #000000;
          height: 315px;
          left: -337px;
          top: -29px;
          transform: translateZ(-1px);
          width: 380px;
          z-index: 0;
        }

        &:after {
          @include pseudo-element;
          background-color: #000000;
          border-radius: 50%;
          left: -1px;
          height: 24px;
          top: 273px;
          width: 24px;
        }
      }
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        &:before {
          display: none;
          @include respond-above(md) {
            @include pseudo-element;
            background-image: url("/assets/box-line.svg");
            background-repeat: no-repeat;
            border-width: 0;
            height: 318px;
            left: -438px;
            top: -31px;
            width: 500px;
          }
        }
      }
    }
  }
}

.simple-card {
  box-shadow: 0 2px 30px 0 $shadow-08;
  background-color: $white;
  clear: both;

  p:last-of-type {
    margin-bottom: 0;
  }
}
.basic-card {
  @include aspect-ratio(145, 93);
  background-color: $white;
  box-shadow: 0 2px 30px 0 $shadow-08;
  height: 100%;

  h2 {
    font-size: 1.25rem;
  }
  > p {
    flex: 1 0 auto;
    margin: auto 0;
  }
  .list-group-item & > a {
    font-family: $primary-font;
  }
}
.action-card {
  height: 100%;
  h2 {
    font-family: $secondary-font-medium;
    font-size: 1.625rem;
  }
  p:first-of-type > span {
    display: inline-block;
    font-weight: 700;
  }

  p {
    flex: 1 0 3rem;
  }

  .btn {
    font-family: $secondary-font-bold;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
.carousel {
  .news-card {
    max-width: 322px;
    @include respond-above(sm) {
      margin: 1.5rem;
    }
  }
  .centered-link {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.news-card + a,
.events-carousel + a {
  display: block;
  font-family: $primary-font;
  font-size: 1.125rem;
  font-weight: bold;
  padding: 0;
}

.card-deck + .card-deck {
  margin-top: 1.5rem;
}
