@include media-breakpoint-up(md) {
  .parsons-layout {
    -webkit-animation: gutter-overflow 0.5s; /* Safari 4+ */
    -moz-animation: gutter-overflow 0.5s; /* Fx 5+ */
    -o-animation: gutter-overflow 0.5s; /* Opera 12+ */
    animation: gutter-overflow 0.5s; /* IE 10+, Fx 29+ */
    @include make-col-offset(-2, 8);
    width: 150%;
  }
}
