@import "../common/header";

header {
  z-index: 20;
  background-image: url("/assets/Line.svg");
  @include respond-above(sm) {
    background-image: url("/assets/Line-alt.svg");
    background-size: 100% 60%;
  }
}

div.header-progress {
  justify-content: center !important;
  padding-left: 15% !important;
}

.header-links {
  margin-top: 0.7rem;
}
