#admin-search-results {
  td,
  th {
    vertical-align: middle;
    text-align: center;
  }
  td {
    font-size: 0.8rem;
    min-width: 80px;
  }
  th {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }
}
