.bg-timeline {
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  z-index: 1;
  width: 4px;
  border-left: solid 4px;
  opacity: 0.5;
  border-image: linear-gradient(to bottom, $secondary 0 calc(100% - 20px), rgba(0, 0, 0, 0)) 1 100%;
  &.fade-in {
    border-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), $secondary 60px calc(100% - 20px), rgba(0, 0, 0, 0)) 1
      100% !important;
  }
}

.timeline {
  z-index: 0;
  background-color: transparent;
  position: relative;
  //.month-label {
  //  padding-left: 32px;
  //}
  .year-label,
  .month-label,
  .hexagon-date {
    position: relative;
    background-color: transparent;
    z-index: 4;
  }
  .date-assignment-list {
    position: relative;
    z-index: 4;
  }
  .month-label:hover,
  .hexagon-date:hover,
  .month-label:focus,
  .hexagon-date:focus {
    outline: none;
    background: linear-gradient(to left, transparent 0, rgba(#636c73, 0.1) 90%, transparent 100%);
    .date-assignment-count,
    .date-toggle-arrow {
      opacity: 1 !important;
      color: black !important;
    }
  }
  .month-label:focus,
  .hexagon-date:focus {
    .date-assignment-count,
    .month-assignment-count {
      font-weight: bold;
    }
  }
  .date-toggle-arrow {
    opacity: 0.5;
    &.open {
      opacity: 1;
    }
  }
}

.timeline-column {
  position: relative;
}

#header-sentinel {
  max-height: 1px;
  overflow: hidden;
  background: transparent;
}

#stickyheader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 255;
  display: none;

  .container {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-top: none;
  }

  &.active {
    display: flex;
    .container {
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    }
  }
}
