#reservation-modal {
  table {
    &.reserved {
      th {
        &.checkbox {
          width: 5%;
        }

        &.student-name {
          width: 50%;
        }

        &.reserved-by {
          width: 17%;
        }
      }
    }
    td {
      .custom-checkbox {
        padding-left: 32px;
      }
    }
  }
}
